@tailwind base;
@tailwind components;
@tailwind utilities;

/* ANIMATION CHANGE COLOR */
.goal {
    -webkit-animation-name: change-color;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;
    animation-name: change-color;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
}

@-webkit-keyframes change-color {
    0% {
        color: #FFFFFF;
    }
    20.0% {
        color: #EAB308;
    }
    40.0% {
        color: #22C55E;
    }
    60.0% {
        color: #3B82F6;
    }
    80.0% {
        color: #EF4444;
    }
    100.0% {
        color: #FFFFFF;
    }
}

@keyframes change-color {
    0% {
        color: #FFFFFF;
    }
    20.0% {
        color: #EAB308;
    }
    40.0% {
        color: #22C55E;
    }
    60.0% {
        color: #3B82F6;
    }
    80.0% {
        color: #EF4444;
    }
    100.0% {
        color: #FFFFFF;
    }
}
/* ANIMATION CHANGE COLOR */

/* ANIMATION BOUNCE */
div .goal-bounce {
    position: relative;
    -webkit-animation: bounce .5s infinite;
    animation: bounce .5s infinite;
}

div .goal-bounce:nth-child(1) {
    animation-delay: .1s;
}
div .goal-bounce:nth-child(2) {
    animation-delay: .2s;
}
div .goal-bounce:nth-child(3) {
    animation-delay: .3s;
}
div .goal-bounce:nth-child(4) {
    animation-delay: .4s;
}
div .goal-bounce:nth-child(5) {
    animation-delay: .5s;
}
div .goal-bounce:nth-child(6) {
    animation-delay: .6s;
}
div .goal-bounce:nth-child(7) {
    animation-delay: .7s;
}
div .goal-bounce:nth-child(8) {
    animation-delay: .8s;
}

@-webkit-keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    50.0% {
        transform: translateY(-40px);
    }
    100.0% {
        transform: translateY(0px);
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0px);
    }
    50.0% {
        transform: translateY(-40px);
    }
    100.0% {
        transform: translateY(0px);
    }
}
/* ANIMATION BOUNCE */

/* ANIMATION SCALE CARD */
.animation-scale {
    rotate: 15deg;
    animation: scale 1s infinite;
    -webkit-animation: scale 1s infinite;
}

@keyframes scale {
    0% {
        transform: scale(.6);
    }
    100.0% {
        transform: scale(1);
    }
}

@-webkit-keyframes scale {
    0% {
        transform: scale(.6);
    }
    100.0% {
        transform: scale(1);
    }
}
/* ANIMATION SCALE CARD */